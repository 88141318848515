<template>
  <div class="app-container">
    <navBar :title="'详情'" @handleGoBack="handleGoBack">
      <template v-slot:icon2>
        <!-- <div class="confirm-btn" @click="handleConfirmClick">确定</div> -->
      </template></navBar
    >
    <div class="wrap mrg-t-20">
      <div class="left-title flex-spa-bet">
        <div class="title">
          <div class="tit">{{ detailObj.realname }}提交的补卡申请</div>
          <div class="name">{{ companyName }}</div>
        </div>
        <!-- 0未提交，1审批中，2已通过，3已驳回 -->
        <img
          v-if="detailObj.approveState == 0"
          src="../../assets/images/sq_record_img3.png"
          alt=""
        />
        <img
          v-if="detailObj.approveState == 3"
          src="../../assets/images/sq_record_img2.png"
          alt=""
        />
        <img
          v-if="detailObj.approveState == 2"
          src="../../assets/images/sq_record_img.png"
          alt=""
        />
      </div>
      <!-- <div class="item-com mrg-b-30">
        <div class="label">审批编号</div>
        <div class="value">2022033015784646</div>
      </div> -->

      <div class="item-com mrg-b-30">
        <div class="label">所在部门</div>
        <div class="value">{{ detailObj.deptName }}</div>
      </div>

      <div class="item-com mrg-b-30">
        <div class="label">补卡班次</div>
        <div class="value">{{ detailObj.workDay }}</div>
      </div>

      <div class="item-com mrg-b-30">
        <div class="label">补卡理由</div>
        <div class="value">{{ detailObj.remark || '--' }}</div>
      </div>
    </div>

    <!-- <div class="flex-spa-bet bot-wrap mrg-t-20">
      <div class="label">查看补卡记录</div>
      <div class="value flex">本月提交3次 <van-icon name="arrow" /></div>
    </div> -->
  </div>
</template>
<script>
export default {
  data() {
    return {
      companyName: localStorage.getItem('companyName'),
      detailObj: {},
    };
  },

  mounted() {
    this.detailObj = this.$route.query;
  },

  methods: {
    handleGoBack() {
      this.$router.replace('/attendance/cardRecord');
        this.$router.go(-1)
    },

    handleConfirmClick() {
      // const applyIdList = this.list.filter((ele) => ele.checked == true).map((ele) => ele.id);
      // this.$store.commit('attendance/SET_APPLY_ID_LIST', applyIdList);
      this.$router.push({
        path: '/attendance/ruleSet',
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.wrap {
  background: #fff;
  padding: 30px 33px;
  .left-title {
    font-size: 34px;
    font-weight: 800;
    color: #333333;
    border-bottom: 1px solid #ececec;
    padding-bottom: 30px;
    margin-bottom: 30px;
    .name {
      font-size: 24px;
      font-weight: 400;
      color: #999999;
      margin-top: 15px;
    }

    img {
      width: 116px;
      height: 90px;
    }
  }

  .item-com {
    .label {
      color: #999999;
      font-size: 24px;
      margin-bottom: 10px;
    }
    .value {
      font-size: 28px;
      font-weight: bold;
      color: #333333;
    }
  }
}
.bot-wrap {
  background: #fff;
  padding: 30px 40px;
  font-size: 30px;
  color: #333333;
  .value {
    font-size: 26px;
    color: #999999;
  }
}
</style>
